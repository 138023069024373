(function () {
  'use strict';

  angular
  .module('neo.home.seasons')
  .config(config);

  function config($stateProvider) {
    $stateProvider
    .state('home.seasons', {
      url: '/seasons',
      templateUrl: 'home/seasons/seasons.tpl.html',
      controller: 'SeasonsCtrl',
      controllerAs: 'vm',
      resolve: {
        seasons: function (Seasons) {
          return Seasons.query().$promise;
        }
      }
    });
  }
}());
